.Collection {
  padding: 60px 0;
}

.Header {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
}

.Header h3 {
  font-size: 24px;
  color: #585976;
}

.CollectionGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 30px;
  margin: 40px 0;
}

.CreateContainer {
  text-align: center;
  margin-top: 80px;
  margin-bottom: -100px;
}

.CreateContainer button {
  cursor: pointer;
  padding: 20px 30px;
  color: white;
  background-color: var(--primary-color);
  font-size: 24px;
  font-weight: bold;
  border: none;
  border-radius: 5px;
}

.DropdownRoot {
}
.Dropdown {
  border: none;
}

.DropdownPlaceholder {
  color: #585976;
}
.DropdownPlaceholder::before {
  content: 'Sort By: ';
}

.DropdownMenu {
  border-radius: 5px;
  border: 1px solid #eeeeee;
}

.DropdownMenu > div {
  padding: 14px 20px;
}

.ConnectDiv {
  color: #888;
  text-align: center;
  margin: 30px 0px;
  padding: 30px;
  background-color: #eee7;
  border-radius: 5px;
}

.ConnectDiv h3 {
  font-weight: 400;
  font-size: 20px;
}

.ConnectDiv button {
  padding: 10px 25px;
  border: none;
  border-radius: 5px;
  background-color: #e8cd00;
  color: white;
  font-size: 17px;
  font-weight: 400;
  font-family: 'CircularStd';
}



@media (max-width: 600px) {
  .Dropdown {
    font-size: 16px;
  }

  .CreateContainer {
    margin-bottom: 0;
  }
  .Header {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
