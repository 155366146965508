.Tags {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
  max-height: 25px;
  justify-content: center;
}

.Tags span {
  border-radius: 100px;
  font-size: 12px;
  padding: 5px 10px;
  color: #919191;
  font-weight: 100;
  background-color: #fffce5;
  text-transform: capitalize;
}

.Tags.Loading {
  margin-top: 30px;
}

.Tags.Loading span {
  background-color: var(--loading-color);
  color: transparent;
}
