.Modal {
  position: fixed;
  background: #000000c4;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 200;
}

.Image {
  width: 80%;
  max-width: 800px;
  max-height: 600px;
  height: 80%;
  object-fit: contain;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
