.CollectionGrid {
  display: grid;
  border-collapse: collapse;
  text-align: center;
  margin-bottom: 100px;
  min-width: 100%;
  grid-template-columns: minmax(50px, 100px) 3fr 1fr 1fr 1fr;
}

.CollectionGrid thead,
.CollectionGrid tbody,
.CollectionGrid tr {
  display: contents;
}

.CollectionGrid td {
  padding: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.CollectionGrid th {
  padding: 16px;
}

.CollectionGrid th {
  background: #fffbe0;
}

.CollectionGrid tbody tr:nth-of-type(odd) td {
  background: #f8f8f8;
}

.CollectionGrid tbody tr:nth-of-type(even) td {
  background: #fffef4;
}

.MobileLabel {
  display: none;
}

/* Loading styles */

.CollectionGrid .Loading td {
  /* color: transparent; */
}

.CollectionGrid .Loading td::after {
  content: '';
  display: block;
  background-color: #77777717;
  width: 100%;
  height: 1.2em;
  border-radius: 2px;
}

.Info {
  background-color: #000;
  color: white;
  height: 17px;
  width: 18px;
  margin-left: 5px;
  border-radius: 50%;
  display: inline-block;
}

.Link,
.Link:active,
.Link:visited {
  color: rgb(0, 75, 145);
  text-decoration: none;
}

.Link:hover {
  text-decoration: underline;
}

@media (min-width: 800px) and (max-width: 1250px) {
  .InfoContent {
    left: -10px;
    top: 50%;
    transform: translate(-100%, -50%);
  }

  .InfoContent::before {
    right: -7px;
    bottom: unset;
    left: unset;
    top: 50%;
    transform: translateY(-50%) rotate(-45deg);
  }
}

@media (max-width: 800px) {
  .MobileLabel {
    display: block;
  }

  .CollectionGrid thead {
    display: none;
  }

  .CollectionGrid {
    display: flex;
    flex-direction: column;
    text-align: left;
    border-radius: 20px;
    overflow: hidden;
    font-size: 14px;
  }

  .CollectionGrid tr {
    display: grid;
    grid-template-columns: 1fr 2fr;
    padding: 20px;
  }
  .CollectionGrid tbody tr:nth-of-type(odd) {
    background: #f8f8f8;
  }

  .CollectionGrid tbody tr:nth-of-type(even) {
    background: #fffef4;
  }
  .CollectionGrid td {
    padding: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
