.Card {
  padding: 40px 35px;
  border-radius: 15px;
  box-shadow: 0px 0px 25px #5859761c;
  text-align: center;
}

.CardThumbContainer {
  padding-top: 150px;
  position: relative;
  margin: auto;
  width: 150px;
}

.CardThumb {
  border: 3px solid #fff4a3;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.Card .Title {
  font-weight: 500;
  font-size: 16px;
  margin: 27px 0px 25px;
}
.EventId {
  margin: 20px auto;
  text-align: center;
}

.EventId a:link {
  color: black;
  text-decoration:none;
}

.EventId span {
  padding: 8px 26px;
  border-radius: 24px;
  font-size: 18px;
  font-weight: bold;
  background-color: #e8cd00;
}

.AdditionalInfoRow {
  display: flex;
  justify-content: center;
  gap: 20px;
  font-size: 12px;
  color: #767680;
  margin: 20px;
}

.AdditionalInfoRow div {
  display: flex;
  gap: 5px;
  align-items: center;
}

.AdditionalInfoRow:last-of-type {
  margin-bottom: 5px;
}

.DetailsGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 14px;
  padding: 20px;
  border: 1px solid #ebebeb;
  border-radius: 10px;
  color: #767680;
  font-size: 14px;
  margin: 24px 0;
}

.DetailsGrid .Completed {
  color: var(--primary-color);
}

.DetailsGrid .Head {
  color: #000000;
}

.Share {
  display: flex;
  gap: 10px;
  justify-content: center;
}

.Share img {
  opacity: 0.5;
  cursor: pointer;
  transition: 0.1s ease-in-out;
  display: block;
}

.Share img:hover {
  opacity: 1;
}


.Loading .CardThumbContainer {
  background-color: #e2e2e2;
  border-radius: 50%;
}

.Loading .CardThumb {
  opacity: 0;
}

.Loading .EventId span {
  background-color: #e2e2e2;
}


.Loading .DetailsGrid .Completed,
.Loading .DetailsGrid .Hash,
.Loading .DetailsGrid .Head {
  background-color: #e2e2e2;
  border-radius: 5px;
  max-width: 150px;
  margin: 0px auto;
  color: transparent;
  width: 100%;
}

.Loading .EventId a:link {
  color: transparent;
  pointer-events: none;
}

.Loading .EventId a:link {
  color: transparent;
  pointer-events: none;
}

.Loading .Share div {
  background-color: #e2e2e2;
  border-radius: 50%;
}

.Loading .Share img{
  opacity: 0;
}

.Loading .Title {
  display: none;
}




