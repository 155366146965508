.Card {
  padding: 40px 35px;
  border-radius: 15px;
  box-shadow: 0px 0px 25px #5859761c;
  text-align: center;
  cursor: pointer;
  transition: 0.2s transform ease-in-out;
}
.Card:hover {
  transform: scale(1.01);
  transition: 0.1s transform ease-in-out;
}
.Card:active {
  transform: scale(1);
  transition: 0.1s transform ease-in-out;
}

.CardThumbContainer {
  padding-top: 150px;
  position: relative;
  margin: auto;
  width: 150px;

  border-radius: 50%;
}

.CardThumb {
  border: 3px solid #fff4a3;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.Label {
  margin: 20px auto;
  text-align: center;
}

.Label span {
  padding: 8px 26px;
  border-radius: 24px;
  font-size: 18px;
  font-weight: bold;
  background-color: #e8cd00;
}

.Card .Title {
  font-weight: 500;
  font-size: 20px;
  margin: 24px 0px 21px;
}

.Card .Desc {
  font-size: 14px;
  color: #767680;
  line-height: 21px;
}

.AdditionalInfoRow {
  display: flex;
  justify-content: center;
  gap: 20px;
  font-size: 12px;
  color: #767680;
  margin: 20px;
}

.AdditionalInfoRow div {
  display: flex;
  gap: 5px;
  align-items: center;
}

.AdditionalInfoRow:last-of-type {
  margin-bottom: 5px;
}

.Loading {
  --loading-color: #eaeaea;
}

/* Loading Styles */

.Loading .CardThumbContainer {
  background: var(--loading-color);
}

.Loading .CardThumb {
  opacity: 0;
}

.Loading.Card .Label span,
.Loading .Title,
.Loading .Desc {
  background-color: var(--loading-color);
  color: transparent;
  margin-left: auto;
  margin-right: auto;
}

.Loading .Title {
  border-radius: 8px;
  max-width: 250px;
}

.Loading .Desc {
  border-radius: 8px;
  max-width: 300px;
  line-height: unset;
  height: 100px;
  margin-top: -10px;
}

.Card.Loading {
  cursor: default;
  position: relative;
  user-select: none;
}

.Card.Loading:hover,
.Card.Loading:active {
  transform: none;
}
.Loading.Card::after {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  content: '';
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0)
  );
  background-position: 0% 0%;
  background-size: 40px 100%;
  background-repeat: no-repeat;
  animation: shine 1.5s ease infinite;
}

@keyframes shine {
  to {
    background-position: right -40px top 0;
  }
}
