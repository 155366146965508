@font-face {
  font-family: 'CircularStd';
  font-weight: 500;

  src: url('./assets//fonts/CircularStd-Medium.woff') format('woff');
}
@font-face {
  font-family: 'CircularStd';

  src: url('./assets//fonts/CircularStd-Book.woff') format('woff');
}

@font-face {
  font-family: 'CircularStd';
  font-weight: bold;
  src: url('./assets//fonts/CircularStd-Bold.woff') format('woff');
  /* font-weight: bold; */
}

:root {
  --primary-color: #e8cd00;
  --secondary-color: #585976;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'CircularStd', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

main {
}

.wrapper {
  margin: 0 auto;
  max-width: 1700px;
  width: 100%;
  padding: 0px 60px;
  transition: all 0.1s ease-in-out;
}

.wrapper.narrow {
  margin: 0 auto;
  max-width: 1400px;
  width: 100%;
  padding: 0px 60px;
}

.primary-color {
  color: #e8cd00;
}

.underline {
  text-decoration: underline;
}

button {
  cursor: pointer;
  transition: transform 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
}

button:active {
  transform: scale(0.9);
}

button:hover {
  transform: scale(1.02) translateY(-2px);
  box-shadow: 1px 1px 48px -10px #c2c2c2;
}
button:active {
  transform: scale(0.95);
  box-shadow: 1px 1px 48px -100px #c2c2c2;
}

.popover-container {
  position: relative;
}

.popover-container:hover .popover-content {
  display: block;
}

.popover-content {
  position: absolute;
  top: -10px;
  transform: translate(-50%, -100%);
  background-color: #444444;
  font-size: 12px;
  font-weight: 100;
  padding: 7px;
  /* width: 201px; */
  font-family: arial;
  border-radius: 4px;
  left: 50%;
  animation: 0.1s ease-in-out fade-in;
  display: none;
  white-space: nowrap;
}

.popover-content.left {
  left: -10px;
  top: 50%;
  transform: translate(-100%, -50%);
}

.popover-content::before {
  content: '';
  background-color: #444444;
  position: absolute;
  width: 15px;
  height: 15px;
  bottom: -7px;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  border-radius: 20px 0px 4px 0px;
  z-index: -1;
}

.popover-content.left::before {
  right: -7px;
  bottom: unset;
  left: unset;
  top: 50%;
  transform: translateY(-50%) rotate(-45deg);
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (max-width: 1600px) {
  .wrapper {
    max-width: 1400px;
  }

  .wrapper.narrow {
    max-width: 1200px;
  }
}

@media (max-width: 1000px) {
  .wrapper,
  .wrapper.narrow {
    padding: 0 30px;
  }
}
