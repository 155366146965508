.Card {
  border-radius: 15px;
  text-align: center;
  box-shadow: 0px 0px 25px #5859761c;
  position: relative;
  overflow: hidden;
  padding: 30px;
  background: #fff;
  z-index: 1;
}

.Background {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 351px;
  object-fit: cover;
}

.ThumbContainer {
  border: 10px solid #fff;
  border-radius: 50%;
  height: 370px;
  width: 370px;
  margin-top: 130px;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
}

.Thumb {
  width: 100%;
  height: 100%;
}

.Card h3 {
  font-size: 36px;
  max-width: 624px;
  margin: 40px auto 24px;
  font-weight: 500;
  color: #585976;
}

.Desc {
  max-width: 624px;
  line-height: 21px;
  font-size: 16px;
  margin: 0 auto;
  color: #767680;
}

.AdditionalInfoRow {
  display: flex;
  justify-content: center;
  gap: 40px;
  font-size: 18px;
  color: #767680;
  margin: 40px;
}

.AdditionalInfoRow > div {
  display: flex;
  gap: 10px;
  align-items: center;
}

.AdditionalInfoRow img {
  height: 24px;
}

.AdditionalInfoRow:last-of-type {
  margin-bottom: 5px;
}

.NoNftsLabel {
  display: block;
  font-size: 9px;
  line-height: 1em;
  margin-bottom: -2px;
}

.NoNftsValue {
  text-align: left;
  display: block;
  color: black;
}

.JoinContainer {
  text-align: center;
  margin: 30px;
  /* margin-bottom: -100px; */
}

.EventJoined,
.NotAvail,
.Connect {
  display: inline-block;
  background: hsl(105deg 60% 86% / 52%);
  color: #76893d;
  /* background: hsl(80deg 32% 49%);
  color: white; */
  padding: 15px 40px;
  border-radius: 5px;
  font-size: 18px;
}

.Connect {
  background: hsl(0deg 0% 53%);
  color: white;
  border: none;
  margin: 30px;
}

.NotAvail {
  background: hsl(0deg 0% 94%);
  color: #9f9f9f;
}

.JoinContainer button {
  cursor: pointer;
  padding: 24px 40px;
  color: white;
  background-color: var(--primary-color);
  font-size: 24px;
  font-weight: bold;
  border: none;
  line-height: 1em;
  border-radius: 5px;
}

.Tags {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
  margin: auto;

  margin-top: 20px;
  max-width: 800px;
  justify-content: center;
}

.Tags span {
  border-radius: 100px;
  font-size: 14px;
  padding: 7px 15px;
  color: #919191;
  font-weight: 100;
  background-color: #fffce5;
  text-transform: capitalize;
}

.SuccessToast a:link {
  color: #e3c100;
}

.SuccessToast a:visited {
  color: #ad9300;
}

/* Loading Styles */
.Loading {
  --loading-color: #eaeaea;
}

.Loading .ThumbContainer {
  background-color: var(--loading-color);
}

.Loading h3,
.Loading .Desc {
  background-color: var(--loading-color);
  color: transparent;
  border-radius: 5px;
}

.Loading .Desc {
  min-height: 100px;
}

.Loading h3::after {
  content: 'loading';
}

.Loading .Thumb {
  opacity: 0;
}

.Loading .Tags span {
  background-color: var(--loading-color);
  color: transparent;
}

.Loading .AdditionalInfoRow span {
  background-color: var(--loading-color);
  color: transparent;
  border-radius: 5px;
  width: 100px;
  /* height: 20px; */
}

.Loading .JoinContainer {
  display: none;
}

/* Loader */
.Loader {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.Loader div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: var(--primary-color);
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.Loader div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.Loader div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.Loader div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.Loader div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

@media (max-width: 2000px) {
  .Background {
    height: 180px;
  }

  .ThumbContainer {
    height: 200px;
    width: 200px;
    margin-top: 20px;
  }
  .Card {
    margin: auto;
  }

  .Card h3 {
    font-size: 30px;
  }

  .Desc {
    max-width: 800px;
  }
  .AdditionalInfoRow img {
    height: 20px;
  }
  .JoinContainer button {
    font-size: 20px;
    padding: 16px 40px;
  }

  .JoinContainer {
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
  }
}

@media (max-width: 800px) {
  .Background {
    height: 150px;
  }

  .ThumbContainer {
    height: 150px;
    width: 150px;
    margin-top: 40px;
  }

  .Card h3 {
    font-size: 24px;
    margin: 25px auto 21px;
  }

  .Desc {
    font-size: 14px;
  }

  .AdditionalInfoRow img {
    height: 16px;
  }

  .AdditionalInfoRow {
    gap: 30px;
    font-size: 12px;
  }
  .AdditionalInfoRow > div {
    gap: 5px;
  }
  .JoinContainer button {
    font-size: 20px;
    padding: 16px 40px;
  }

  .JoinContainer {
    margin: auto;
    margin-top: 30px;
  }
}
