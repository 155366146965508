.Gallery {
  padding: 60px 0;
}

.Header {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
}

.Header h2 {
  font-size: 36px;
}

.GalleryGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 30px;
  margin: 40px 0;
}

.LoadMoreContainer {
  text-align: center;
  margin-top: 80px;
  position: relative;
  z-index: 10;
  margin-bottom: -100px;
}

.LoadMoreContainer button {
  cursor: pointer;
  padding: 20px 30px;
  color: white;
  background-color: var(--primary-color);
  font-size: 24px;
  font-weight: bold;
  border: none;
  border-radius: 5px;
}

.DropdownContainer {
  display: flex;
}
.DropdownRoot {
}

.Dropdown {
  border: none;
}

.DropdownPlaceholder {
  color: #585976;
}
.DropdownPlaceholder::before {
  content: 'Order By: ';
}

.DropdownMenu {
  border-radius: 5px;
  border: 1px solid #eeeeee;
}

.DropdownMenu > div {
  padding: 14px 20px;
}

.SearchInputContainer {
  justify-self: flex-end;
}

.NoEvent {
  color: #888;
  text-align: center;
  margin: 30px 0px;
  padding: 30px;
  background-color: #eee7;
  border-radius: 5px;
  margin-bottom: 50px;
}

.NoEvent h3 {
  font-weight: 400;
  font-size: 20px;
}

@media (max-width: 1000px) {
  .Header {
    grid-template-columns: 1fr;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 21px;
  }

  .Gallery h2 {
    margin: 5px;
  }

  .SearchInputContainer {
    justify-self: center;
  }

  .DropdownContainer {
    grid-row: 2;
    justify-content: center;
  }

  .LoadMoreContainer {
    margin-bottom: 0px;
  }
}
