.Form {
  display: flex;
  gap: 50px;
  flex-direction: column;
}

.FormSection {
  padding: 60px;
  position: relative;
}

.FormSection::before {
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  backdrop-filter: blur(11px);
  border-radius: 20px;
  border: 2px solid var(--primary-color);
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.65);
  z-index: -1;
}

.SectionTitle {
  color: #585976;
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  margin: 20px 0;
  margin-top: 60px;
}

.SectionTitle:first-child {
  margin-top: 0;
}

#Section4Title {
  margin-bottom: 40px;
}

.Section3InputGrid,
.Section4InputGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
}

.TwoRow {
  grid-column: 1/3;
}

.CreateContainer {
  text-align: center;
  /* margin-top: 80px;
	margin-bottom: -100px; */
}

.CreateContainer button {
  cursor: pointer;
  padding: 20px 30px;
  color: white;
  background-color: var(--primary-color);
  font-size: 24px;
  font-weight: bold;
  border: none;
  border-radius: 5px;
}

.FormSection:nth-child(1)::after,
.FormSection:nth-child(2)::after,
.FormSection:nth-child(3)::after {
  content: '';
  display: block;
  width: 350px;
  height: 350px;
  filter: blur(50px);
  opacity: 42px;
  background-color: var(--primary-color);
  opacity: 0.42;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  right: 0;
  z-index: -4;
  transform: translate(50%, -50%);
}

.FormSection:nth-child(2)::after {
  width: 200px;
  height: 200px;
  transform: translate(50%, -30%);
}

.FormSection:nth-child(3)::after {
  width: 400px;
  height: 450px;
  top: 0;
  left: 0;
  right: auto;
  transform: translate(-50%, -50%);
}

@media (max-width: 1600px) {
  .Section3InputGrid,
  .Section4InputGrid {
    gap: 20px;
  }
}

@media (max-width: 1000px) {
  .Form {
    gap: 30px;
  }
  .Section3InputGrid,
  .Section4InputGrid {
    grid-template-columns: 1fr;
  }

  .TwoRow {
    grid-column: auto;
  }

  .FormSection {
    padding: 50px 30px;
  }

  .SectionTitle {
    font-size: 26px;
    margin: 20px 0;
    margin-top: 60px;
  }
}
